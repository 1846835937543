
import { Options, prop, Vue } from "vue-class-component";

class Props {
  flag = prop<string>({
    type: String,
    default: "",
  });
  isMisroute = prop<boolean>({
    type: Boolean,
    default: false,
  });
  isTransit = prop<boolean>({
    type: Boolean,
    default: false,
  });
}
@Options({
  components: {},
})
export default class UnloadingLoading extends Vue.with(Props) {}
