
import { Options, prop, Vue } from "vue-class-component";
class Props {
  flag = prop<string>({
    type: String,
    default: "",
  });
}
@Options({
  components: {},
})
export default class POD extends Vue.with(Props) {}
