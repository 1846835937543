
import {
  convertDecimal,
  convertDecimalWithComma,
  downloadCsv,
  formatDateNormal,
  parsingErrorResponse,
} from "@/app/infrastructures/misc/Utils";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { StiDestController } from "@/app/ui/controllers/StiDestController";
import OutGoingMixins from "@/app/ui/views/out-going-shipment/out-going-mixin";
import { StiDestReverseScanRequest } from "@/data/payload/api/StiDestApiRequest";
import {
  ListSTIDestTemporary,
  RequestListSTIDestTemporary,
  ReverseScan,
  STIDestReverseScan,
  STIDestTemporary,
} from "@/domain/entities/StiDest";
import debounce from "lodash/debounce";
import { mixins, Options } from "vue-class-component";
import EmptyScan from "../component/illustration/image/empty-scan.vue";
import Invalid from "../component/illustration/image/invalid.vue";
import Illustration from "../component/illustration/index.vue";
@Options({
  components: {
    Illustration,
    EmptyScan,
    Invalid,
  },
})
export default class STIDestUpdateReverseScan extends mixins(OutGoingMixins) {
  pagination = {
    page: 1,
    limit: 50,
  };
  searchValue: any = "";
  refs: any = "";
  misroute = {
    title: "Misroute",
    status: false,
  };
  isSTT: any = false;
  isTransit = false;
  isPartial = false;
  isMisroute = false;
  flag: any = "";
  isInvalid = false;
  isErrorValidationScan = false;
  savedSttNo = "";
  statusReturn: any = "";
  isScan = false;
  errorCaption = "";
  cityCode = "";
  listLoading = false;
  // form model
  form = {
    sttNumber: "",
  };
  dataSttToBeUpdate = new ListSTIDestTemporary({
    data: [],
  });
  errorType = "";

  get isModal(): boolean {
    return (
      this.openSuccess ||
      this.isFailed
    );
  }
  get openSuccess(): boolean {
    return StiDestController.isOpenSuccess;
  }
  setOpenSuccess(value: boolean) {
    StiDestController.setOpenSuccess(value);
  }

  onCloseSuccess() {
    this.setOpenSuccess(false)
    this.onResetState()
    this.dataSttToBeUpdate = new ListSTIDestTemporary({
      data: []
    })
    this.pagination = {
    page: 1,
    limit: 50,
  };
  this.searchValue = "";
  this.misroute = {
    title: "Misroute",
    status: false,
  };
  this.savedSttNo = "";
  }
  // failed for create
  get isFailed(): boolean {
    return StiDestController.isFailed;
  }
  setFailed(value: boolean) {
    StiDestController.setFailed(value);
  }

  setIllustrationByResponse(
  ): void {
    const lastData = this.dataSttToBeUpdate.data[0];
    if (lastData) {
      const isSTT = lastData?.sdtBagNo === "";
      if (isSTT) {
        this.isSTT = isSTT
        this.isTransit = lastData.sdtIsTransit === 1;
        this.cityCode = lastData.sdtDestination;
        this.isMisroute= lastData.sdtIsMisroute === 1;
        this.flag = lastData.sdtFlag;
        this.isPartial = false;
        this.savedSttNo = lastData.sdtSttNo;
        if (this.flag && (this.isMisroute || this.isTransit)) {
          this.flag = "";
        }
      } else {
        this.isSTT = false;
        this.cityCode = "";
        this.savedSttNo = lastData.sdtBagNo;
        const findBag = this.dataSttToBeUpdate.data
          .filter(item => item.sdtBagNo == lastData.sdtBagNo)
        const isAllTransit = findBag.every((key) => key.sdtIsTransit);      
        if (isAllTransit) {
          this.isTransit = true;
          return;
        }
        const isAllMisroute = findBag.every((key) => key.sdtIsMisroute); 
        if (isAllMisroute) {
          this.isMisroute = true;
          return;
        }
        const checkAnyTransit = findBag.some((key) => key.sdtIsTransit);
        if (checkAnyTransit) {
          this.isTransit = true;
          this.isPartial = true;
          return;
        }
        const checkAnyMisroute = findBag.some((key) => key.sdtIsMisroute);
        if (checkAnyMisroute) {
          this.isMisroute = true;
          this.isPartial = true;
          return;
        }
        const isFlag = findBag.some((key) => key.sdtFlag);
        if (isFlag) {
          this.flag = "flag";
          return;
        }
      }
    }
  }

  get alertPopup() {
    // if success
    if (this.openSuccess)
      return {
        onClick: () => this.onCloseSuccess(),
        title: "Perubahan Status Berhasil !",
        message: "Status STT berhasil diupdate menjadi STI-DEST.",
        image: "image-modal-success"
      };
    // default is failed
    return {
      onClick: () => this.setFailed(false),
      title: "Perubahan Status Gagal !",
      message: "Perubahan status STI-DEST gagal dibuat.",
      image: "image-modal-failed"
    };
  }

  beforeMount() {
    this.getSession();
  }
  mounted() {
    this.onResetState();
  }

  onSetFocusRefs() {
    this.refs = this.$refs;
  }

  onSearch(value: string) {
    this.searchValue = value;
    this.pagination.page = 1;
    this.getSession();
  }

  clearSearch() {
    this.searchValue = "";
    this.getSession();
  }

  setIsMisroute() {
    this.misroute.status = !this.misroute.status;
    this.getSession();
  }

  get statusReturnList() {
    return [
      { name: "Penanda Paket", value: "" },
      { name: "Segera POD", value: "Segera POD" },
      { name: "Segera RTS", value: "Segera RTS" },
      { name: "Segera HAL", value: "Segera HAL" },
    ];
  }

  isOpenStatusReturn = false;
  onOpenSelect() {
    this.isOpenStatusReturn = true;
  }

  onCloseSelect() {
    this.isOpenStatusReturn = false;
  }

  onSelectStatusReturn(name: string, value: string) {
    this.onCloseSelect();
    this.statusReturn = value;
    this.getSession();
  }
  onChangeStt() {
    this.errorCaption = "";
    this.isInvalid = false;
    this.isUnpaid = false;
  }

  //via scan
  addScanSttNumber = debounce(() => {
    if (!this.isDisableAddSttNumber) {
      this.isErrorValidationScan = false;
      this.isScan = true;
      this.addSttNumberToUpdate();
    }
  }, 500);

  addSttNumber() {
    if (!this.isDisableAddSttNumber) {
      this.isScan = false;
      this.isErrorValidationScan = false;
      this.addSttNumberToUpdate();
    }
  }

  checkIsSTT(val: string) {
    return (
      !!val.trim().match(/^\d{1,2}LP\w+$/g) ||
      !!val.trim().match(/^\w{1,3}-\w+$/g)
    );
  }

  get errorMessageModal() {
    return MainAppController.errorMessageModal;
  }

  onResetState() {
    this.isSTT = false;
    this.isTransit = false;
    this.isMisroute = false;
    this.flag = "";
    this.isErrorValidationScan = false;
    this.isInvalid = false;
    this.cityCode = "";
    this.isPartial = false;
    this.isUnpaid = false;
  }

  async addSttNumberToUpdate() {
    this.onValidateSttResult();
    this.savedSttNo = "";
    if (this.isErrorValidationScan) return;
    const resp = await this.reverseScan();
    if (resp.success) {
      this.handleResponse(resp?.resp as any);
      this.form.sttNumber = "";
    }
    const inputStt: any = this.refs.inputStt;
    inputStt?.focus();
  }

  isUnpaid = false;
  handleSTTUnpaid() {
    this.isUnpaid = true;
    playNotification("error");
  }

  scanSTT(resp: STIDestReverseScan) {
    this.isSTT = true;
      this.savedSttNo = resp.stt.sttNo;
      this.flag = resp.stt.flag;
      this.isMisroute = resp.stt.isMissroute;
      this.isTransit = resp.stt.isTransit;
      if (this.flag && (this.isMisroute || this.isTransit)) { 
        this.flag = "";
      }
      this.cityCode = resp.stt.sttDestinationCityId;
      this.dataSttToBeUpdate.data.unshift(
        new STIDestTemporary({
          sdtSttNo: resp?.stt.sttNo,
          sdtBagNo: resp.stt.bagNo,
          sdtProduct: resp.stt.sttProductType,
          sdtOrigin: resp.stt.sttOriginCityId,
          sdtOriginName: resp.stt.sttOriginCityName,
          sdtDestinationName: resp.stt.sttDestinationCityName,
          sdtDestination: resp.stt.sttDestinationCityId,
          sdtDeadlineReturn: resp.stt.deadlineReturn,
          sdtIsMisroute: this.isMisroute ? 1 : 0,
          sdtIsTransit: this.isTransit ? 1 : 0,
          sdtStatusReturn: resp.stt.statusReturn,
          sdtFlag: resp.stt.flag,
          sdtPieces: resp.stt.sttTotalPiece,
          sdtRefNo: resp.stt.sttNoRefExternal,
          sdtCreatedAt: resp.stt.sttBookedAt,
          sdtGrossWeight: resp.stt.sttGrossWeight,
          sttAssessmentStatus: resp.stt.sttAssessmentStatus
        })
      );
      playNotification(this.flag ? this.getSoundByFlag(this.flag) : this.isMisroute ? "misroute" : this.getSoundByFlag(""));
  }

  scanBag(resp: STIDestReverseScan) {
    this.isSTT = false;
      resp.bag.forEach((key: ReverseScan) => {
        this.dataSttToBeUpdate.data.unshift(
          new STIDestTemporary({
            sdtBagNo: key.bagNo,
            sdtSttNo: key.sttNo,
            sdtProduct: key.sttProductType,
            sdtOrigin: key.sttOriginCityId,
            sdtOriginName: key.sttOriginCityName,
            sdtDestination: key.sttDestinationCityId,
            sdtDestinationName: key.sttDestinationCityName,
            sdtDeadlineReturn: key.deadlineReturn,
            sdtIsMisroute: key.isMissroute ? 1 : 0,
            sdtIsTransit: key.isTransit ? 1 : 0,
            sdtStatusReturn: key.statusReturn,
            sdtFlag: key.flag,
            sdtPieces: key.sttTotalPiece,
            sdtRefNo: key.sttNoRefExternal,
            sdtCreatedAt: key.sttBookedAt,
            sdtGrossWeight: key.sttGrossWeight,
            sttAssessmentStatus: key.sttAssessmentStatus
          })
        );
      });
      this.savedSttNo = this.dataSttToBeUpdate.data[0].sdtBagNo;
        const checkAllTransit = resp.bag.every(
          (key) => key.isTransit
        );
        if (checkAllTransit) {
          this.isTransit = true;
          playNotification(this.isPartial ? "bongkar" : this.isMisroute ? "misroute" : this.getSoundByFlag(""));
          return;
        }

        const checkAllMisroute = resp.bag.every(
          (key) => key.isMissroute
        );

        if (checkAllMisroute) {
          this.isMisroute = true;
          playNotification(this.isPartial ? "bongkar" : this.isMisroute ? "misroute" : this.getSoundByFlag(""));
          return;
        }
        const checkAnyTransit = resp.bag.some(
          (key) => key.isTransit
        );
        if (checkAnyTransit) {
          this.isTransit = true;
          this.isPartial = true;
          playNotification(this.isPartial ? "bongkar" : this.isMisroute ? "misroute" : this.getSoundByFlag(""));
          return;
        }
        const checkAnyMisroute = resp.bag.some(
          (key) => key.isMissroute
        );
        if (checkAnyMisroute) {
          this.isMisroute = true;
          this.isPartial = true;
          playNotification(this.isPartial ? "bongkar" : this.isMisroute ? "misroute" : this.getSoundByFlag(""));
          return;
        }
      const checkAnyFlag = resp.bag.some(
        (key) => key.flag
      );
      if (checkAnyFlag) {
        this.flag = "Kiriman penting, Delivery ulang, RTS, HAL, SLA hari ini, Lewat batas SLA";
        playNotification("bongkar");
        return;
      }
      playNotification(this.getSoundByFlag("")); 
  }

get totalStt() {
  return this.dataSttToBeUpdate.data.length;
}

get calculatedStt() {
  return this.dataSttToBeUpdate.data.reduce(
    (a: any, b: any) => {
      return {
        sttTotalPiece: a.sttTotalPiece + b.sdtPieces,
        sttGrossWeight:
          a.sttGrossWeight +
          b.sdtGrossWeight,
      };
    },
    {
      sttTotalPiece: 0,
      sttGrossWeight: 0
    }
  );
}

// convert value decimal
isValueDecimal(value: number): number | string {
  return convertDecimal(value);
}

  // format total kg
convertTotalKg(value: number) {
  return convertDecimalWithComma(value, 2);
}

get totalPieces() {
  return this.calculatedStt.sttTotalPiece;
}
get totalGrossWeight() {
  return this.calculatedStt.sttGrossWeight;
}

  handleResponse(resp: STIDestReverseScan) {
    this.onResetState();
    if (
      resp?.stt.sttNo &&
      this.checkIsSTT(resp.stt.sttNo) &&
      (!resp?.bag.length || !resp?.bag[0].bagNo)
    ) {
      // stt
      this.scanSTT(resp);
    } else {
      // bag
      this.scanBag(resp);
    }
  }

  async reverseScan() {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    this.isInvalid = false;
    this.isErrorValidationScan = false;
    try {
      const resp = await StiDestController.stiDestReverseScan(
        new StiDestReverseScanRequest({
          bagOrSttNo: this.form.sttNumber,
        })
      );
      this.errorType = "";
      // re focus
      return { success: true, resp };
    } catch (error) {
      const err = (error as any).response.data?.message?.id?.toLowerCase();
      this.isErrorValidationScan = err.includes("tidak ditemukan");
      this.isInvalid = true;
      //handle validation stt unpaid
      if (err.includes("stt belum/kurang bayar")) {
        this.handleSTTUnpaid(); 
      }
      playNotification("error");
      this.form.sttNumber = "";
      return { success: false, error: error as any };
    } finally {
      MainAppController.closeLoading();
    }
  }

  onValidateSttResult() {
    const indexCurrentSttNumber = this.dataSttToBeUpdate.data.findIndex(
      (data: STIDestTemporary) => {
        return (
          data.sdtSttNo === this.form.sttNumber.trim() ||
          data.sdtBagNo === this.form.sttNumber.trim() ||
          data.sdtRefNo === this.form.sttNumber.trim()
        );
      }
    );
    if (indexCurrentSttNumber > -1) {
      //play notification error
      playNotification("error");
      this.errorCaption = "No. STT/Bag sudah di input";
      this.isErrorValidationScan = true;
      this.isInvalid = true;
    }
    // to do check
  }

  get isDisableAddSttNumber() {
    return !this.form.sttNumber;
  }

  getRowStyle(status: string): string {
    const statusLowerCase = status.toLowerCase();
    if (statusLowerCase.includes("segera pod")) return "row-red";
    if (statusLowerCase.includes("diambil penerima")) return "row-purple";
    if (
      statusLowerCase.includes("segera rts") ||
      statusLowerCase.includes("segera hal")
    )
      return "row-yellow";
    return "";
  }
  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-10 text-center whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: STIDestTemporary, index: number) => {
          const number =
            index === 0
              ? this.dataSttToBeUpdate.data.length
              : this.dataSttToBeUpdate.data.length - index;
          return `<div class="text-black-lp-300 ${this.getRowStyle(
            item.sdtStatusReturn
          )}">${number -
            this.pagination.limit * (this.pagination.page - 1)}</div>`;
        },
      },
      {
        name: "No. Bag",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: STIDestTemporary) => {
          return `<div class="text-black-lp-300 ${this.getRowStyle(
            item.sdtStatusReturn
          )}">${item.sdtBagNo || "-"}</div>`;
        },
      },
      {
        name: `No. STT`,
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: STIDestTemporary) => {
          return `<div class="text-black-lp-300 ${this.getRowStyle(
            item.sdtStatusReturn
          )}">
              ${item.sdtSttNo || "-"}
              ${
                item.sttAssessmentStatus === "waiting" ||
                item.sttAssessmentStatus === "rejected"
                  ? '<div class="unpaid-chips">STT perlu diadjust</div>'
                  : ""
              } 
          </div>`;
        },
      },
      {
        name: `No. Referensi`,
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: STIDestTemporary) => {
          return `<div class="text-black-lp-300 break-all ${this.getRowStyle(
            item.sdtStatusReturn
          )}">${item.sdtRefNo || "-"}</div>`;
        },
      },
      {
        name: "Batas POD",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: STIDestTemporary) => {
          return `<div class="text-black-lp-300 flex ${this.getRowStyle(
            item.sdtStatusReturn
          )}">
                    <div class="flex">
                        ${
                          item.sdtDeadlineReturn
                            ? formatDateNormal(
                                item.sdtDeadlineReturn,
                                "dddd, DD MMMM YYYY"
                              )
                            : "-"
                        }
                    </div>
                  </div>`;
        },
      },
      {
        name: "Jenis Pengiriman",
        styleHead: "w-44 text-left whitespace-no-wrap",
        render: (item: STIDestTemporary) => {
          return `<div class="text-black-lp-300 flex ${this.getRowStyle(
            item.sdtStatusReturn
          )}">
                    <div class="rounded px-2 py-0 bg-white">
                        ${item.sdtProduct || "-"}
                    </div>
                  </div>`;
        },
      },
      {
        name: "Kota Asal",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: STIDestTemporary) => {
          return `<div class="text-black-lp-300 flex ${this.getRowStyle(
            item.sdtStatusReturn
          )}">
                    <div class="rounded px-2 py-0 bg-white">
                        ${item.sdtOrigin || "-"}
                    </div>
                  </div>`;
        },
      },
      {
        name: "Kota Tujuan",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: STIDestTemporary) => {
          return `<div class="text-black-lp-300 flex ${this.getRowStyle(
            item.sdtStatusReturn
          )}">
                    <div class="rounded px-2 py-0 bg-white">
                        ${item.sdtDestination || "-"}
                    </div>
                  </div>`;
        },
      },
      {
        name: "Transit",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: STIDestTemporary) => {
          return `${this.handleStyleStatus(
            item.sdtIsTransit,
            item.sdtStatusReturn
          )}`;
        },
      },
      {
        name: "Mis-Route",
        styleHead: "w-32 text-left whitespace-no-wrap",
        render: (item: STIDestTemporary) => {
          return `${this.handleStyleStatus(
            item.sdtIsMisroute,
            item.sdtStatusReturn
          )}`;
        },
      },
      {
        name: "Penanda Paket",
        styleHead: "w-32 text-left",
        key: "button_column",
        styleButton: (item: STIDestTemporary) => {
          return {
            flat: true,
            compact: true,
            class: "w-full",
            icon: "",
            render: (item: STIDestTemporary) => {
              return `<div class="text-black-lp-300 ${this.getRowStyle(
                item.sdtStatusReturn
              )}">${item.sdtStatusReturn}</div>`;
            },
          };
        },
      },
    ];
  }

  handleStyleStatus(item: any, status: string) {
    if (item > 0 || item)
      return `<div class=${this.getRowStyle(
        status
      )}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 3.5C9.61305 3.5 7.32387 4.44821 5.63604 6.13604C3.94821 7.82387 3 10.1131 3 12.5C3 13.6819 3.23279 14.8522 3.68508 15.9442C4.13738 17.0361 4.80031 18.0282 5.63604 18.864C6.47177 19.6997 7.46392 20.3626 8.55585 20.8149C9.64778 21.2672 10.8181 21.5 12 21.5C13.1819 21.5 14.3522 21.2672 15.4442 20.8149C16.5361 20.3626 17.5282 19.6997 18.364 18.864C19.1997 18.0282 19.8626 17.0361 20.3149 15.9442C20.7672 14.8522 21 13.6819 21 12.5C21 10.1131 20.0518 7.82387 18.364 6.13604C16.6761 4.44821 14.3869 3.5 12 3.5ZM15.3364 11.3364C15.6879 10.9849 15.6879 10.4151 15.3364 10.0636C14.9849 9.71213 14.4151 9.71213 14.0636 10.0636L11.1 13.0272L9.9364 11.8636C9.58492 11.5121 9.01508 11.5121 8.6636 11.8636C8.31213 12.2151 8.31213 12.7849 8.6636 13.1364L10.4636 14.9364C10.8151 15.2879 11.3849 15.2879 11.7364 14.9364L15.3364 11.3364Z" fill="#36B37E"/></svg></div>`;
    else
      return `<div class=${this.getRowStyle(
        status
      )}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.63604 6.13604C7.32387 4.44821 9.61305 3.5 12 3.5C14.3869 3.5 16.6761 4.44821 18.364 6.13604C20.0518 7.82387 21 10.1131 21 12.5C21 13.6819 20.7672 14.8522 20.3149 15.9442C19.8626 17.0361 19.1997 18.0282 18.364 18.864C17.5282 19.6997 16.5361 20.3626 15.4442 20.8149C14.3522 21.2672 13.1819 21.5 12 21.5C10.8181 21.5 9.64778 21.2672 8.55585 20.8149C7.46392 20.3626 6.47177 19.6997 5.63604 18.864C4.80031 18.0282 4.13738 17.0361 3.68508 15.9442C3.23279 14.8522 3 13.6819 3 12.5C3 10.1131 3.94821 7.82387 5.63604 6.13604ZM8.85 9.35C9.20147 8.99853 9.77132 8.99853 10.1228 9.35L12 11.2272L13.8772 9.35C14.2287 8.99853 14.7985 8.99853 15.15 9.35C15.5015 9.70147 15.5015 10.2713 15.15 10.6228L13.2728 12.5L15.15 14.3772C15.5015 14.7287 15.5015 15.2985 15.15 15.65C14.7985 16.0015 14.2287 16.0015 13.8772 15.65L12 13.7728L10.1228 15.65C9.77132 16.0015 9.20147 16.0015 8.85 15.65C8.49853 15.2985 8.49853 14.7287 8.85 14.3772L10.7272 12.5L8.85 10.6228C8.49853 10.2713 8.49853 9.70147 8.85 9.35Z" fill="#B82025"/>
</svg></div>`;
  }


  goBack() {
    this.$router.push("/sti-dest");
  }

  async getSession() {
    try {
      this.listLoading = true;
      MainAppController.closeErrorMessage();
      this.dataSttToBeUpdate = await StiDestController.getListStiDestTemporary(
        new RequestListSTIDestTemporary({
          sttOrBagNo: this.searchValue,
          isMisroute: this.misroute.status,
          statusReturn: this.statusReturn,
        })
      );
      this.dataSttToBeUpdate.data.reverse();
      this.setIllustrationByResponse();
    } catch (error) {
      this.errorType = parsingErrorResponse(error).type;
    } finally {
      this.listLoading = false;
    }
  }

  handleDownloadCsv() {
    downloadCsv({
      fileName: "STI-DEST Manifest.csv",
      fields: [
        "Nomor Bagging",
        "Nomor STT",
        "Nomor Referensi",
        "Nama Produk",
        "Batas POD",
        "Total Koli",
        "Total Berat Kotor",
        "Kota Asal",
        "Kota Tujuan",
        "Transit",
        "Misroute",
        "Penanda STT",
        "Tanggal Manifest"
      ],
      listStt: this.mappingDataCsv(this.dataSttToBeUpdate.data),
    });
  }

  // mapping success csv
  mappingDataCsv(data: any): any[] {
    const temp: any = [];
    data.map((stt: any) => {
      temp.push({
        No: temp.length + 1,
        "Nomor Bagging": stt.sdtBagNo || "-",
        "Nomor STT": stt.sdtSttNo,
        "Nomor Referensi": stt.sdtRefNo || "-",
        "Nama Produk": stt.sdtProduct,
        "Batas POD": formatDateNormal(
          stt.sdtDeadlineReturn,
          "DD/MM/YY"
        ),
        "Total Koli": stt.sdtPieces,
        "Total Berat Kotor" : stt.sdtGrossWeight, 
        "Kota Asal": `${stt.sdtOrigin} - ${stt.sdtOriginName}`,
        "Kota Tujuan":`${stt.sdtDestination} - ${stt.sdtDestinationName}`,
        Transit: stt.sdtIsTransit === 1 ? "YA" : "TIDAK",
        Misroute: stt.sdtIsMisroute === 1 ? "YA" : "TIDAK",
        "Penanda STT": stt.sdtStatusReturn,
        "Tanggal Manifest": ""
      });
    });
    return temp;
  }

  async printManifest(id: number) {
    const printManifest = await StiDestController.getDetailStiDest({
      id: Number(id),
      version: "v2",
    });

    if (printManifest) {
      const detailManifest = StiDestController.stiDestDetail;
      const print: any = await import(
        "@/app/ui/views/incoming-process/sti-dest/modules/print-reverse-scan.vue"
      );
      const logo = await import(
        `@/app/ui/assets/images/logo-${this.roleAccount}.png`
      );
      print.default.methods.printManifest(detailManifest, logo.default);
    }
  }

  get roleAccount() {
    return (
      (this.dataProfile.account_type === "partner"
        ? this.dataProfile.account_type_detail.type
        : this.dataProfile.account_type) || "internal"
    );
  }

  get dataProfile() {
    return AccountController.accountData;
  }
  
  async handleProcessManifest() {
    await StiDestController.stiDestProcessReverseScan()
    this.printManifest(StiDestController.StiDestIdToGeneratePdf)
  }

  getSoundByFlag(flag: string): string {
    const lowerCaseFlag = flag.toLowerCase();
    // implement notification sound here
    // decide sound notif
    if (lowerCaseFlag.includes("sla") || lowerCaseFlag.includes("ulang")) return "pod";
    else if (lowerCaseFlag.includes("rts")) return "rts";
    else if (lowerCaseFlag.includes("hal")) return "hal";
    else if (lowerCaseFlag.includes("penting")) return "urgent_delivery";
    else return "success";
  }
}
