
import { Options, prop, Vue } from "vue-class-component";
import OverlayPanel from "primevue/overlaypanel";
import Skeleton from "@/app/ui/components/skeleton/index.vue";

class Props {
  totalPod = prop<number>({
    default: 0
  });
  totalRts = prop<number>({
    default: 0
  });
  totalHal = prop<number>({
    default: 0
  });
  isLoading = prop<boolean>({
    default: false
  });
}

@Options({
  components: {
    OverlayPanel,
    Skeleton
  }
})
export default class HeaderSTTShouldDel extends Vue.with(Props) {
  get totalPodString() {
    return this.totalPod.toLocaleString("id");
  }

  get totalRtsString() {
    return this.totalRts.toLocaleString("id");
  }

  get totalHalString() {
    return this.totalHal.toLocaleString("id");
  }

  onClickCard(stt: string) {
    this.$router.push(`/urgent-delivery-management/urgent-${stt}`);
  }
}
