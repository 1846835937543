
import { Options, prop, Vue } from "vue-class-component";
import Transit from "./image/transit.vue";
import Misroute from "./image/misroute.vue";
import POD from "./image/pod.vue";
import HAL from "./image/hal.vue";
import RTS from "./image/rts.vue";
import STIDest from "./image/stidest.vue";
import Invalid from "./image/invalid.vue";
import UnloadingLoading from "./image/unloading-loading.vue";
import TakenByRecipient from "./image/taken-by-recipient.vue";

class Props {
  cityCode = prop<string>({
    type: String,
    default: "",
  });
  flag = prop<string>({
    type: String,
    default: "",
  });
  isSTT = prop<boolean>({
    type: Boolean,
    default: false,
  });
  isTransit = prop<boolean>({
    type: Boolean,
    default: false,
  });
  isMisroute = prop<boolean>({
    type: Boolean,
    default: false,
  });
  isPartial = prop<boolean>({
    type: Boolean,
    default: false,
  });
}
@Options({
  components: {
    Transit,
    Misroute,
    POD,
    HAL,
    RTS,
    STIDest,
    Invalid,
    UnloadingLoading,
    TakenByRecipient,
  },
})
export default class Illustration extends Vue.with(Props) {
  get isPOD() {
    return (
      this.flag.toLowerCase() === "kiriman penting" ||
      this.flag.toLowerCase() === "lewat batas sla" ||
      this.flag.toLowerCase() === "sla hari ini" ||
      this.flag.toLowerCase() === "delivery ulang"
    );
  }

  get isRTS() {
    return (
      this.flag.toLowerCase() === "lewat batas rts" ||
      this.flag.toLowerCase() === "rts sekarang" ||
      this.flag.toLowerCase() === "rts besok"
    );
  }

  get isTakenByRecipient() {
    return (
      this.flag.toLowerCase() === "diambil penerima"
    );
  }
}
