import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-2" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_POD = _resolveComponent("POD")!
  const _component_RTS = _resolveComponent("RTS")!
  const _component_TakenByRecipient = _resolveComponent("TakenByRecipient")!
  const _component_HAL = _resolveComponent("HAL")!
  const _component_Transit = _resolveComponent("Transit")!
  const _component_Misroute = _resolveComponent("Misroute")!
  const _component_STIDest = _resolveComponent("STIDest")!
  const _component_UnloadingLoading = _resolveComponent("UnloadingLoading")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.isSTT)
      ? (_openBlock(), _createBlock("div", _hoisted_2, [
          (_ctx.flag)
            ? (_openBlock(), _createBlock("div", _hoisted_3, [
                (_ctx.isPOD)
                  ? (_openBlock(), _createBlock(_component_POD, {
                      key: 0,
                      flag: _ctx.flag
                    }, null, 8, ["flag"]))
                  : (_ctx.isRTS)
                    ? (_openBlock(), _createBlock(_component_RTS, {
                        key: 1,
                        flag: _ctx.flag
                      }, null, 8, ["flag"]))
                    : (_ctx.isTakenByRecipient)
                      ? (_openBlock(), _createBlock(_component_TakenByRecipient, { key: 2 }))
                      : (_openBlock(), _createBlock(_component_HAL, { key: 3 }))
              ]))
            : (_openBlock(), _createBlock("div", _hoisted_4, [
                (_ctx.isTransit)
                  ? (_openBlock(), _createBlock(_component_Transit, {
                      key: 0,
                      cityCode: _ctx.cityCode
                    }, null, 8, ["cityCode"]))
                  : (_ctx.isMisroute)
                    ? (_openBlock(), _createBlock(_component_Misroute, {
                        key: 1,
                        cityCode: _ctx.cityCode
                      }, null, 8, ["cityCode"]))
                    : (_openBlock(), _createBlock(_component_STIDest, { key: 2 }))
              ]))
        ]))
      : (_openBlock(), _createBlock("div", _hoisted_5, [
          (_ctx.flag)
            ? (_openBlock(), _createBlock(_component_UnloadingLoading, {
                key: 0,
                flag: _ctx.flag
              }, null, 8, ["flag"]))
            : (_openBlock(), _createBlock("div", _hoisted_6, [
                (_ctx.isPartial)
                  ? (_openBlock(), _createBlock("div", _hoisted_7, [
                      (_ctx.isTransit)
                        ? (_openBlock(), _createBlock(_component_UnloadingLoading, {
                            key: 0,
                            isTransit: _ctx.isTransit
                          }, null, 8, ["isTransit"]))
                        : _createCommentVNode("", true),
                      (_ctx.isMisroute)
                        ? (_openBlock(), _createBlock(_component_UnloadingLoading, {
                            key: 1,
                            isMisroute: _ctx.isMisroute
                          }, null, 8, ["isMisroute"]))
                        : _createCommentVNode("", true)
                    ]))
                  : (_openBlock(), _createBlock("div", _hoisted_8, [
                      (_ctx.isTransit)
                        ? (_openBlock(), _createBlock(_component_Transit, {
                            key: 0,
                            isBag: ""
                          }))
                        : (_ctx.isMisroute)
                          ? (_openBlock(), _createBlock(_component_Misroute, {
                              key: 1,
                              isBag: ""
                            }))
                          : (_openBlock(), _createBlock(_component_STIDest, { key: 2 }))
                    ]))
              ]))
        ]))
  ]))
}