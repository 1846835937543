import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { AccountController } from "@/app/ui/controllers/AccountController";
import en from "@/i18n/locales/en";
import id from "@/i18n/locales/id";
import my from "@/i18n/locales/my";
import { createI18n } from "vue-i18n";
import { FlagsPermissionOutgoingShipmentSTI } from "@/feature-flags/flags-out-going-shipment-sti";

const generateSummaryPdf = async (
  title: string,
  data: { name: string; value: string }[],
  logo: string
) => {
  const i18nInstance = createI18n({
    legacy: false,
    locale: AccountController.accountData.account_type_detail.countryCode.toLowerCase(),
    fallbackLocale: "id",
    globalInjection: false,
    messages: {
      en,
      id,
      my
    }
  });
  const translate = i18nInstance.global.t;
  const doc = new jsPDF("p", "mm", "a4", true);
  let y = 11;

  const pdfWidth = doc.internal.pageSize.width;
  const logoProperties = doc.getImageProperties(logo);
  const logoAspectRatio = logoProperties.height / logoProperties.width;

  const imageWidth = 40;
  const imageHeight = logoAspectRatio * 40;
  doc.addImage(
    logo,
    "PNG",
    pdfWidth - imageWidth - 9,
    y,
    imageWidth,
    imageHeight,
    "logo",
    "FAST",
    0
  );
  doc.setFont("helvetica", "bold");
  doc.setFontSize(16);
  doc.setTextColor("#4d4d4d");

  y += 7;
  doc.text(title, 10, y);
  doc.setFillColor("#e0e0e0");

  y += 7;
  doc.rect(10, y, 190, 0.2, "F");
  doc.setFontSize(7);
  doc.setFont("helvetica", "normal");
  doc.setFontSize(20);
  doc.setTextColor("#4d4d4d");

  for (const [index, item] of data.entries()) {
    y += index ? 20 : 13;

    doc.setFont("helvetica", "normal");
    doc.text(translate(item.name), 10, y);
    doc.setFont("helvetica", "bold");
    doc.text(`: ${item.value}`, 90, y);
  }

  const reverseScan = FlagsPermissionOutgoingShipmentSTI.flag_reverse_scan_sti_enable.isEnabled();
  y = reverseScan && data.length < 8 ? 190 : 263;
  doc.setFont("helvetica", "normal");
  doc.setFontSize(16);
  doc.text("Station Admin", 10, y);
  doc.text(translate("Kurir"), 90, y);
  doc.text("Operations Supervisor", 142, y);

  y += 20;
  doc.setFontSize(6);
  doc.text("(.......................................................)", 14, y);
  doc.text("(.......................................................)", 84, y);
  doc.text("(.......................................................)", 155, y);

  doc.setProperties({
    title
  });
  doc.autoPrint();
  window.open(doc.output("bloburl").toString(), "_blank");
};

export default generateSummaryPdf;
