
import { FlagsPermissionIncomingProcessSTIDest } from "@/feature-flags/flags-incoming-process-sti-dest";
import { Options, Vue } from "vue-class-component";
import STIDestDetailReverseScan from "../reverse-scan/sti-dest-detail-reverse-scan.vue";
import DetailOldSTIDest from "./detail.vue";
@Options({
  components: {
    DetailOldSTIDest,
    STIDestDetailReverseScan,
  },
})
export default class STIDestDetail extends Vue {
  get isReverseScanSTIDest() {
    return FlagsPermissionIncomingProcessSTIDest.flag_reverse_scan_sti_dest_enable.isEnabled();
  }
}
