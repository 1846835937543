
import { AccountController } from "@/app/ui/controllers/AccountController";
import { Vue } from "vue-class-component";
import mappingDataPdf from "./manifest-pdf";
import generateSummaryPdf from "@/app/ui/modules/summary";

export default class Print extends Vue {
  // get profile account
  get dataProfile() {
    return AccountController.accountData;
  }

  // format Date
  formatDate(date: Date) {
    return this.$moment(date).format("DD MMMM YYYY, HH:mm");
  }

  async printManifest(data: any, logo: string) {
    mappingDataPdf(data, logo);
  }

  async printSummary(data: any, id: number, logo: string) {
    generateSummaryPdf(`STI-DEST Manifest - ${id}`, data, logo);
  }
  // role account user
  get roleAccount() {
    return (
      (this.dataProfile.account_type === "partner"
        ? this.dataProfile.account_type_detail.type
        : this.dataProfile.account_type) || "internal"
    );
  }
}
