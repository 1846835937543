
import { Options, prop, Vue } from "vue-class-component";
import OverlayPanel from "primevue/overlaypanel";
import Skeleton from "@/app/ui/components/skeleton/index.vue";

class Props {
  totalDelNow = prop<number>({
    default: 0
  });
  totalDelIntracity = prop<number>({
    default: 0
  });
  totalDelIntercity = prop<number>({
    default: 0
  });
  isLoading = prop<boolean>({
    default: false
  });
}

@Options({
  components: {
    OverlayPanel,
    Skeleton
  }
})
export default class HeaderSTTShouldDel extends Vue.with(Props) {
  toolTip1 = false;
  statusChipsStyle = true;

  toggleStatusPanel(event: any, name: string) {
    const refs: any = this.$refs;
    if (name === "tooltipStatus") {
      this.toolTip1 = !this.toolTip1;
    }
    refs[name].toggle(event);
  }

  get totalStt() {
    return this.totalDelNow.toLocaleString("id");
  }

  onClickCard() {
    this.$router.push(
      "/sti-dest/need-to-sti-dest-del?filter-now=true&filter-overdue=false"
    );
  }
}
