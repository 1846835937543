
import { Options,prop, Vue } from "vue-class-component";
import Skeleton from "@/app/ui/components/skeleton/index.vue";

class Props {
  totalCargoTruck = prop<number>({
    default: 0
  });
  totalCargoPlane = prop<number>({
    default: 0
  });
  totalCargoShip = prop<number>({
    default: 0
  });
  totalSttNeedStiDest = prop<number>({
    default: 0
  });
  isLoading = prop<boolean>({
    default: false
  });
}

@Options({
  components: {
    Skeleton
  }
})
export default class HeaderBeforeSTIDest extends Vue.with(Props) {
  mounted() {
    this.adjustFontSize();
    window.onload = this.adjustFontSize;
    window.addEventListener("resize", this.adjustFontSize);
  }

  adjustFontSize() {
    const textContainer = document.getElementById('textContainer') as HTMLElement;
    const textCargo1 = document.getElementById('textCargo1') as HTMLElement;
    const textCargo2 = document.getElementById('textCargo2') as HTMLElement;
    const textCargo3 = document.getElementById('textCargo3') as HTMLElement;
    const textDivider1 = document.getElementById('textDivider1') as HTMLElement;
    const textDivider2 = document.getElementById('textDivider2') as HTMLElement;

    if (!textContainer) {
      return;
    }

    let fontCargoSize = 60;
    let fontDividerSize = 24;
    const minCargoFontSize = 28;

    while (textContainer.scrollWidth > textContainer.clientWidth && fontCargoSize > minCargoFontSize) {
      fontCargoSize -= 1;
      fontDividerSize -= 0.5;
      textCargo1.style.fontSize = `${fontCargoSize}px`;
      textCargo2.style.fontSize = `${fontCargoSize}px`;
      textCargo3.style.fontSize = `${fontCargoSize}px`;
      textDivider1.style.fontSize = `${fontDividerSize}px`;
      textDivider2.style.fontSize = `${fontDividerSize}px`;
    }
  }

  onClickCard() {
    this.$router.push(
      "/sti-dest/need-to-sti-dest-del?filter-now=false&filter-overdue=false"
    );
  }
}
