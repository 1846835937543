
  import { Vue, Options } from "vue-class-component";
  import UpdateNotification from "@/app/ui/views/incoming-process/sti-dest/component/update-notification.vue";
  import convertDecimalWithComma from "@/app/infrastructures/misc/common-library/ConvertDecimalWithComma";
  import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
  import dateToDateString from "@/app/infrastructures/misc/common-library/DateToDateString";
  import { StiDestController } from "@/app/ui/controllers/StiDestController";
  import Print from "./modules/print.vue";
  import EmptyState from "@/app/ui/components/empty-list/index.vue";
  import { StiDestData, StiDestManifestData } from "@/domain/entities/StiDest";
  import { AccountController } from "@/app/ui/controllers/AccountController";
  import { INCOMING_PROCESS_STI_DEST } from "@/app/infrastructures/misc/RolePermission";
  import { checkRolePermission } from "@/app/infrastructures/misc/Utils";
  
  @Options({
    components: {
      UpdateNotification,
      Print,
      EmptyState
    }
  })
  export default class StationTransitInDest extends Vue {
    refs: any = "";
    print: any = "";
    beforeMount() {
      StiDestController.setFilter({
        startDate: "",
        endDate: "",
        search: ""
      });
    }
    mounted() {
      this.refs = this.$refs;
      StiDestController.setFirstPage();
    }
  
    updateNotificationModel = false;
  
    fetchStiDestList() {
      StiDestController.fetchStiDestList();
    }
  
    get getTitle() {
      return this.$route.meta.title;
    }
  
    get ableToCreate() {
    return checkRolePermission(INCOMING_PROCESS_STI_DEST.CREATE);
    }

    get ableToDetail() {
    return checkRolePermission(INCOMING_PROCESS_STI_DEST.DETAIL);
    }

  
    // loading
    get isLoading() {
      return StiDestController.isLoading;
    }
  
    // error cause
    get errorCause() {
      return StiDestController.errorCause;
    }
  
    // filter date
    get startDate() {
      return StiDestController.filter.startDate;
    }
  
    get endDate() {
      return StiDestController.filter.endDate;
    }
  
    setDateRange(value: Array<any>) {
      StiDestController.setStartDate(value[0]);
      StiDestController.setEndDate(value[1]);
      StiDestController.setFirstPage();
      value[0] && value[1] && this.fetchStiDestList();
    }
  
    // filter search
    get searchValue() {
      return StiDestController.filter.search;
    }
  
    onSearch(value: string) {
      StiDestController.setSearch(value);
      StiDestController.setFirstPage();
      this.fetchStiDestList();
    }
  
    clearSearch() {
      StiDestController.setSearch("");
      this.fetchStiDestList();
    }
  
    // go to update sti-dest
    goToUpdate() {
      this.$router.push("/sti-dest/update");
    }
  
    goToDetail(item: StiDestData) {
      this.$router.push(`/sti-dest/detail/${item.stiDestId}`);
    }
  
    // Table
    get columns() {
      return [
        {
          name: "No.",
          styleHead: "w-12 text-left whitespace-no-wrap",
          render: (item: any, index: number) => {
            return `<div class="overflow-ellipsis text-left">${index +
              1 +
              this.pagination.limit * (this.pagination.page - 1)}</div>`;
          }
        },
        {
          name: "ID Manifest",
          styleHead: "w-28 text-left whitespace-no-wrap",
          render: (item: StiDestData) => {
            return `<div class="overflow-ellipsis text-left">${item.stiDestId}</div>`;
          }
        },
        {
          name: "Kota Kedatangan",
          styleHead: "w-48 text-left whitespace-no-wrap",
          render: (item: StiDestData) => {
            return `<div class="overflow-ellipsis text-left">${item.stiDestArrivalCityName}</div>`;
          },
          tooltipHeader: () => {
            return `<div class="text-black-lp-300 font-semibold text-12px">Kota Kedatangan</div><div class='mt-2 text-gray-lp-500 text-12px'>Kota Kedatangan adalah kota dimana<br />barang/STT tiba ketika diproses pada fitur<br />STI-DEST.</div>`;
          }
        },
        {
          name: "Total STT",
          styleHead: "w-16 text-left whitespace-no-wrap",
          render: (item: StiDestData) => {
            return `<div class="overflow-ellipsis text-left">${item.stiDestTotalStt.toLocaleString(
              "id"
            )}</div>`;
          }
        },
        {
          name: "Total Koli",
          styleHead: "w-24 text-left whitespace-no-wrap",
          render: (item: StiDestData) => {
            return `<div class="overflow-ellipsis text-left">${item.stiDestTotalPiece.toLocaleString(
              "id"
            )}</div>`;
          }
        },
        {
          name: "Total Gross Weight",
          styleHead: "w-40 text-left whitespace-no-wrap",
          render: (item: StiDestData) => {
            return `<div class="overflow-ellipsis text-left">${convertDecimalWithComma(
              item.stiDestTotalGrossWeight,
              2
            )} Kg</div>`;
          }
        },
        {
          name: "Total Volume Weight",
          styleHead: "w-50 text-left whitespace-no-wrap",
          render: (item: StiDestData) => {
            return `<div class="overflow-ellipsis text-left">${convertDecimalWithComma(
              item.stiDestTotalVolumeWeight,
              2
            )} Kg</div>`;
          }
        },
        {
          name: "Tanggal STI-DEST",
          styleHead: "w-50 text-left",
          render: (item: StiDestData) => {
            return `<div class="text-black-lp-300">${formatDateWithoutTime(
              item.stiDestCreatedAt
            )}</div>
              <div class="capitalize text-gray-lp-500">${
                item.stiDestCreatedBy
              }</div>`;
          }
        },
        {
          name: "Action",
          key: "button_column",
          styleHead: "w-24 text-left",
          styleCustom: "padding-action-sti",
          styleButton: (item: any) => {
            return {
              outline: true,
              icon: "option-gray",
              title: "",
              textColor: "black",
              color: "gray-lp-400",
              customClass: "button-option",
              clickFunction: () => null,
              groupOptions: [
                {
                  icon: "print-summary",
                  label: "Print Summary",
                  clickFunction: () => this.printSummary(item.stiDestId)
                },
                {
                  icon: "printer",
                  label: "Print PDF",
                  clickFunction: () => this.printManifest(item.stiDestId)
                },
                {
                  icon: "download-gray",
                  label: "Download Excel",
                  clickFunction: () =>
                    StiDestController.downloadStiDest(item.stiDestId)
                }
              ]
            };
          }
        }
      ];
    }
  
    // custom button
    customButton = {
      outline: true,
      title: "Print",
      textColor: "black-lp-300",
      color: "gray-lp-400",
      small: false,
      clickFunction: (item: any) => this.refs.print.printManifest(item.stiDestId)
    };
  
    get pagination() {
      return StiDestController.stiDestList.pagination;
    }
  
    get stiDestList() {
      return StiDestController.stiDestList.data;
    }
  
    async printSummary(id: number) {
      const printManifest = await StiDestController.getStiDestManifestDetail({
        id
      });
  
      if (printManifest) {
        const detail: StiDestManifestData = StiDestController.StiDestManifestData;
        const data: Array<any> = [
          {
            name: "Kota Kedatangan",
            value: `${detail.stiDestCityCode} - ${detail.stiDestCityName}`
          },
          {
            name: "Nama Consolidator",
            value: AccountController.accountData.name
          },
          {
            name: "Tanggal STI-DEST",
            value: dateToDateString(detail.stiDestCreatedAt)
          },
          {
            name: "Total STT",
            value: String(detail.stiDestTotalStt)
          },
          {
            name: "Total Koli",
            value: String(detail.stiDestTotalPieces)
          },
          {
            name: "Total Berat Kotor",
            value: `${convertDecimalWithComma(
              detail.stiDesttotalGrossWeight,
              2
            )} Kg`
          },
          {
            name: "Total Berat Dimensi",
            value: `${convertDecimalWithComma(
              detail.stiDesttotalVolumeWeight,
              2
            )} Kg`
          }
        ];
  
        const print: any = await import(
          "@/app/ui/views/incoming-process/sti-dest/modules/print.vue"
        );
        const logo = await import(
          `@/app/ui/assets/images/logo-${this.roleAccount}.png`
        );
        print.default.methods.printSummary(data, id, logo.default);
      }
    }
  
    async printManifest(id: number) {
      const printManifest = await StiDestController.getStiDestManifestDetail({
        id
      });
  
      if (printManifest) {
        const detailManifest = StiDestController.StiDestManifestData;
        const print: any = await import(
          "@/app/ui/views/incoming-process/sti-dest/modules/print.vue"
        );
        const logo = await import(
          `@/app/ui/assets/images/logo-${this.roleAccount}.png`
        );
        print.default.methods.printManifest(detailManifest, logo.default);
      }
    }
  
    get roleAccount() {
      return (
        (this.dataProfile.account_type === "partner"
          ? this.dataProfile.account_type_detail.type
          : this.dataProfile.account_type) || "internal"
      );
    }
  
    get dataProfile() {
      return AccountController.accountData;
    }
  }
  