
import { FlagsPermissionIncomingProcessSTIDest } from "@/feature-flags/flags-incoming-process-sti-dest";
import { Options, Vue } from "vue-class-component";
import STIDestOldList from "./sti-dest-old-list.vue";
import STIDestListReverseScan from "./reverse-scan/sti-dest-list-reverse-scan.vue";
@Options({
  components: {
    STIDestOldList,
    STIDestListReverseScan,
  },
})
export default class StationTransitInDest extends Vue {

  get isReverseScanSTIDest() {
    return FlagsPermissionIncomingProcessSTIDest.flag_reverse_scan_sti_dest_enable.isEnabled();
  }
}
