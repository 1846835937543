
import { Options, prop, Vue } from "vue-class-component";
class Props {
  cityCode = prop<string>({
    type: String,
    default: "",
  });
  isBag = prop<boolean>({
    type: Boolean,
    default: false,
  });
}
@Options({})
export default class Transit extends Vue.with(Props) {}
