
import { Options, prop, Vue } from "vue-class-component";
import InvalidUnpaid from "../image/invalid-unpaid.vue";
class Props {
  isUnpaid = prop<boolean>({
    type: Boolean,
    default: false,
  });
}
@Options({
  components: {
    InvalidUnpaid
  },
})
export default class Invalid extends Vue.with(Props) {}
