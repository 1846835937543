
import STIDestUpdateReverseScan from "../../reverse-scan/sti-dest-update-reverse-scan.vue";
import STIDestUpdateV1 from "../update/index.vue";

import { Options, Vue } from "vue-class-component";
import { FlagsPermissionIncomingProcessSTIDest } from "@/feature-flags/flags-incoming-process-sti-dest";
@Options({
  components: {
    STIDestUpdateReverseScan,
    STIDestUpdateV1,
  },
})
export default class STIDestUpdate extends Vue {
  get isReverseScanSTIDest() {
    return FlagsPermissionIncomingProcessSTIDest.flag_reverse_scan_sti_dest_enable.isEnabled();
  }
}
