
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import CreateUpdate from "@/app/ui/components/data-wrapper/create-update.vue";
import OverlayPanel from "primevue/overlaypanel";
import {
  convertDecimalWithComma,
  formatDate,
  formatDateNormal,
} from "@/app/infrastructures/misc/Utils";
import { StiDestController } from "@/app/ui/controllers/StiDestController";
import { AccountController } from "@/app/ui/controllers/AccountController";

@Options({
  components: {
    DetailLayout,
    CreateUpdate,
    OverlayPanel,
  },
})
export default class STIDestDetailReverseScan extends Vue {
  refs: any = "";
  mounted() {
    this.refs = this.$refs;
    this.getDetail();
  }

  get id(): number {
    return Number(this.$route.params.id);
  }

  get title() {
    return `STI-DEST Manifest - ${this.id}`;
  }

  // route navigation
  goBack() {
    const path =
      (this.$route.meta as any)["parent-path"] || this.$route.matched[0].path;
    this.$router.push(path);
  }

  getDetail() {
    StiDestController.getDetailStiDest({
      id: Number(this.$route.params.id),
      version: "v2",
    });
  }

  get detailData() {
    return StiDestController.stiDestDetail;
  }

  get convertCreatedAt() {
    return formatDate(this.detailData.stiDestDate);
  }

  get isLoading() {
    return StiDestController.isLoading;
  }
  get isError() {
    return StiDestController.isError;
  }
  get errorCause() {
    return StiDestController.errorCause;
  }

  //table
  get columns() {
    return [
      {
        name: "No.",
        key: "no",
        styleHead: "w-10 text-left whitespace-nowrap",
        render: (item: any, index: number) => {
          return `<div class="text-black-lp-300">${index + 1}</div>`;
        },
      },
      {
        name: `No. Bag`,
        key: "bag",
        styleHead: "w-48 text-left whitespace-nowrap",
        render: (item: any) => {
          return `<div class="text-black-lp-300 whitespace-nowrap">${item.sttBagVendorNo ||
            item.sttBagNo}</div>`;
        },
      },
      {
        name: `No. STT`,
        key: "stt",
        styleHead: "w-48 text-left whitespace-nowrap",
        render: (item: any) => {
          return `<div class="text-black-lp-300 whitespace-nowrap">${item.sttNo || "-"}</div>`;
        },
      },
      {
        name: `No. Referensi`,
        key: "reference",
        styleHead: "w-48 text-left whitespace-nowrap",
        render: (item: any) => {
          return `<div class="text-black-lp-300 whitespace-nowrap break-all">${item.sttNoRefExternal || "-"}</div>`;
        },
      },
      {
        name: "Jenis Pengiriman",
        key: "productType",
        styleHead: "w-48 text-left whitespace-nowrap",
        render: (item: any) => {
          return `<div class="text-black-lp-300 rounded px-2 py-0 bg-gray-lp-400 whitespace-nowrap">
                      ${item.sttProductType}
                  </div>`;
        },
      },
      {
        name: "Komoditas",
        key: "commodity",
        styleHead: "w-48 text-left whitespace-nowrap",
        render: (item: any) => {
          return `<div class="text-black-lp-300">${item.sttCommodityName}</div>`;
        },
      },
      {
        name: "Total Koli",
        key: "piece",
        styleHead: "w-24 text-left whitespace-nowrap",
        render: (item: any) => {
          return `<div class="text-black-lp-300">${item.sttTotalPiece}</div>`;
        },
      },
      {
        name: "Gross Weight",
        key: "grossWeight",
        styleHead: "w-24 text-left whitespace-nowrap",
        render: (item: any) => {
          return `<div class="text-black-lp-300">${convertDecimalWithComma(
            item.sttGrossWeight,
            2
          )} Kg</div>`;
        },
      },
      {
        name: "Volume Weight",
        key: "volumeWeight",
        styleHead: "w-32 text-left whitespace-nowrap",
        render: (item: any) => {
          return `<div class="text-black-lp-300">${convertDecimalWithComma(
            item.sttVolumeWeight,
            2
          )} Kg</div>`;
        },
      },
      {
        name: "Kota Asal",
        key: "origin",
        styleHead: "w-32 text-left whitespace-nowrap",
        render: (item: any) => {
          return `<div class="text-black-lp-300 rounded px-2 py-0 bg-gray-lp-400 whitespace-nowrap">
                      ${item.origin}
                  </div>`;
        },
      },
      {
        name: "Kota Tujuan",
        key: "destinatio",
        styleHead: "w-32 text-left whitespace-nowrap",
        render: (item: any) => {
          return `<div class="text-black-lp-300 rounded px-2 py-0 bg-gray-lp-400 whitespace-nowrap">
                      ${item.destination}
                  </div>`;
        },
      },
      {
        name: "Transit",
        key: "transit",
        styleHead: "w-24 text-left whitespace-nowrap",
        render: (item: any) => {
          return item.isTransit === 0
            ? '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604ZM8.85 8.85C9.20147 8.49853 9.77132 8.49853 10.1228 8.85L12 10.7272L13.8772 8.85C14.2287 8.49853 14.7985 8.49853 15.15 8.85C15.5015 9.20147 15.5015 9.77132 15.15 10.1228L13.2728 12L15.15 13.8772C15.5015 14.2287 15.5015 14.7985 15.15 15.15C14.7985 15.5015 14.2287 15.5015 13.8772 15.15L12 13.2728L10.1228 15.15C9.77132 15.5015 9.20147 15.5015 8.85 15.15C8.49853 14.7985 8.49853 14.2287 8.85 13.8772L10.7272 12L8.85 10.1228C8.49853 9.77132 8.49853 9.20147 8.85 8.85Z" fill="#B82025"/></svg>'
            : '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 9.61305 20.0518 7.32387 18.364 5.63604C16.6761 3.94821 14.3869 3 12 3ZM15.3364 10.8364C15.6879 10.4849 15.6879 9.91508 15.3364 9.5636C14.9849 9.21213 14.4151 9.21213 14.0636 9.5636L11.1 12.5272L9.9364 11.3636C9.58492 11.0121 9.01508 11.0121 8.6636 11.3636C8.31213 11.7151 8.31213 12.2849 8.6636 12.6364L10.4636 14.4364C10.8151 14.7879 11.3849 14.7879 11.7364 14.4364L15.3364 10.8364Z" fill="#36B37E"/></svg>';
        },
      },
      {
        name: "Misroute",
        key: "misroute",
        styleHead: "w-24 text-left whitespace-nowrap",
        render: (item: any) => {
          return item.isMisroute === 0
            ? '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604ZM8.85 8.85C9.20147 8.49853 9.77132 8.49853 10.1228 8.85L12 10.7272L13.8772 8.85C14.2287 8.49853 14.7985 8.49853 15.15 8.85C15.5015 9.20147 15.5015 9.77132 15.15 10.1228L13.2728 12L15.15 13.8772C15.5015 14.2287 15.5015 14.7985 15.15 15.15C14.7985 15.5015 14.2287 15.5015 13.8772 15.15L12 13.2728L10.1228 15.15C9.77132 15.5015 9.20147 15.5015 8.85 15.15C8.49853 14.7985 8.49853 14.2287 8.85 13.8772L10.7272 12L8.85 10.1228C8.49853 9.77132 8.49853 9.20147 8.85 8.85Z" fill="#B82025"/></svg>'
            : '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 9.61305 20.0518 7.32387 18.364 5.63604C16.6761 3.94821 14.3869 3 12 3ZM15.3364 10.8364C15.6879 10.4849 15.6879 9.91508 15.3364 9.5636C14.9849 9.21213 14.4151 9.21213 14.0636 9.5636L11.1 12.5272L9.9364 11.3636C9.58492 11.0121 9.01508 11.0121 8.6636 11.3636C8.31213 11.7151 8.31213 12.2849 8.6636 12.6364L10.4636 14.4364C10.8151 14.7879 11.3849 14.7879 11.7364 14.4364L15.3364 10.8364Z" fill="#36B37E"/></svg>';
        },
      },
      {
        name: "Penanda Paket",
        key: "statusReturn",
        styleHead: "w-32 text-left whitespace-nowrap",
        render: (item: any) => {
          return `<div class="text-black-lp-300">${item.statusReturn}</div>`;
        },
      },
    ];
  }

  async print(type: string) {
    const data: any = [
    {
            name: "Kota Kedatangan",
            value: `${this.detailData.originCityCode} - ${this.detailData.originConsolidator}`
          },
          {
            name: "Nama Consolidator",
            value: this.detailData.stiDestPartnerName
          },
          {
            name: "Tanggal Manifest",
            value: formatDateNormal(this.detailData.stiDestDate, "DD MMMM YYYY HH:mm:ss")
          },
          {
            name: "Total STT/Koli",
            value: `${String(this.detailData.totalStt)}/${String(this.detailData.totalPiece)}`
          },
          {
            name: "Transit",
            value: this.detailData.stiDestTotalTransit === 0 ? "Tidak" : this.detailData.stiDestTotalTransit
          },
          {
            name: "Misroute",
            value: this.detailData.stiDestTotalMisroute === 0 ? `Tidak` : this.detailData.stiDestTotalMisroute
          },
          {
            name: "Segera POD",
            value: this.detailData.stiDestTotalStatusReturnPOD === 0 ? `Tidak` : this.detailData.stiDestTotalStatusReturnPOD
          },
          {
            name: "Segera RTS",
            value: this.detailData.stiDestTotalStatusReturnRTS === 0 ? `Tidak` : this.detailData.stiDestTotalStatusReturnRTS
          },
          {
            name: "Segera HAL",
            value: this.detailData.stiDestTotalStatusReturnHal === 0 ? `Tidak` : this.detailData.stiDestTotalStatusReturnHal
          }
    ];

    const print: any = await import(
      "@/app/ui/views/incoming-process/sti-dest/modules/print-reverse-scan.vue"
    );
    const logo = await import(
      `@/app/ui/assets/images/logo-${this.roleAccount}.png`
    );
    const printType: any = {
      summary: () =>
        print.default.methods.printSummary(
          data,
          this.detailData.stiDestId,
          logo.default
        ),
      manifest: () =>
        print.default.methods.printManifest(this.detailData, logo.default),
    };

    printType[type]();
  }

  get roleAccount() {
    return (
      (this.dataProfile.account_type === "partner"
        ? this.dataProfile.account_type_detail.type
        : this.dataProfile.account_type) || "internal"
    );
  }

  get dataProfile() {
    return AccountController.accountData;
  }

  convertDecimalWithComma(value: any, round: number) {
    return convertDecimalWithComma(value, round);
  }
}
